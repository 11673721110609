import { G as GroupInfo, R as RecordForm } from "./index33.js";
import "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "./GroupInfo.vue_vue_type_style_index_0_scoped_true_lang.js";
import "./audit.others.js";
import "./audit.status.js";
import "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-audit-record" }, [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("page-header", { attrs: { "title": "Add Audit Record", "back": _vm.backTo, "has-tag": false } }), _c("div", { staticClass: "mx-5 my-5" }, [_c("a-row", { attrs: { "gutter": [24, 32] } }, [_c("a-col", { attrs: { "span": 16 } }, [_c("record-form")], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("group-info", { attrs: { "item-id": _vm.itemId, "group-data": _vm.groupEntity } })], 1)], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateAuditRecord",
  inject: ["resourceProps"],
  components: {
    GroupInfo,
    RecordForm
  },
  data() {
    const [groupProps, historyProps] = this.resourceProps;
    const { groupId, itemId } = this.$route.query;
    const groupCustomApiUrl = `${apiUrl$1}/sterilization/audit-groups/${groupId}`;
    return {
      apiUrl: apiUrl$1,
      groupCustomApiUrl,
      groupProps,
      historyProps,
      groupId,
      itemId,
      dataItem: {},
      preItems: [
        {
          title: "Data Entry",
          path: ""
        },
        {
          title: "Audit Group",
          path: "/sterilization-audits/audit-groups"
        }
      ]
    };
  },
  computed: {
    groupEntity() {
      return this.groupProps.crud.getEntity(this.groupId) || {};
    },
    menuItems() {
      return [
        ...this.preItems,
        {
          title: this.groupEntity.groupName,
          path: `/sterilization-audits/audit-groups/${this.groupEntity.id}`
        },
        {
          title: `${this.dataItem.itemCode}`,
          path: `/sterilization-audits/audit-groups/${this.groupEntity.id}/item/${this.dataItem.id}`
        },
        {
          title: "Add Audit Record",
          path: ""
        }
      ];
    }
  },
  async mounted() {
    await this.groupProps.crud.fetchEntity(this.groupCustomApiUrl);
    if (this.groupEntity && this.groupEntity.auditItems && this.itemId) {
      this.dataItem = this.groupEntity.auditItems.find((item) => item.id == this.itemId);
    }
  },
  methods: {
    backTo() {
      const back = `/sterilization-audits/audit-groups/${this.groupEntity.id}/item/${this.dataItem.id}`;
      this.$router.push(back);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateAuditRecord = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-audit-record-container" }, [_c("resource", { attrs: { "name": ["sterilization.audit-groups", "sterilization.audit-groups.items.histories"], "api-url": _vm.apiUrl, "create": _vm.CreateAuditRecord } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateAuditRecordIndex",
  data() {
    return {
      apiUrl,
      CreateAuditRecord
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
